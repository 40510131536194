@import "components/global";

html {
  font-family: ui-rounded, "Hiragino Maru Gothic ProN", Quicksand, Comfortaa,
    Manjari, "Arial Rounded MT", "Arial Rounded MT Bold", Calibri,
    source-sans-pro, sans-serif;
  font-size: 62.5%;
}

body {
  color: $c-text-dark;
  letter-spacing: 0.01em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $scale;
  margin: 0 0 $scale1 0;
  padding: 0;
  line-height: $scale1;
  font-weight: 100;
}

h1 {
  font-weight: 300;
  font-size: $scale2 + $scale-5;
}

h2 {
  font-size: $scale2;
  font-weight: 100;
  margin: $scale-3 0 $scale-5 0;
}

p {
  line-height: $scale2;
  margin-bottom: $scale;
  font-size: $body-font-size;
}

a {
  font-size: $body-font-size;
  color: $c-blue-300;
}

p:last-child {
  margin-bottom: 0;
}

blockquote {
  font-style: italic;
  line-height: $scale2;
  font-size: $body-font-size;
}

cite {
  display: block;
  font-style: normal;
  opacity: 0.7;
  margin: $scale1 0 0 0;
}

textarea,
input {
  font-size: $body-font-size;
}

textarea {
  resize: vertical;
}

.capitalize {
  text-transform: capitalize;
}
