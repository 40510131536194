@import "components/global";

main.home,
main.signup,
main.signin {
  padding-bottom: 0;

  @include respond-to-device("mobile") {
    padding: 0;
  }

  h1 {
    font-size: $scale5;
    color: $c-dark-300;
    margin: 0;
    margin-bottom: $scale-4;
    line-height: $scale;
  }

  h2 {
    font-weight: 100;
    margin: $scale-3 0 $scale 0;
    @include respond-to-device("mobile") {
      font-size: 23px;
    }
  }

  .home textarea {
    resize: vertical;
  }

  nav,
  .hero {
    max-width: 80%;
    margin: auto;
    @include respond-to("portrait") {
      max-width: 100%;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
  }

  nav .hello {
    color: $c-dark-100;
  }
  nav a {
    margin: $scale;
    width: 90px;
    @include respond-to("portrait") {
      margin: $scale2;
    }
  }

  nav button {
    margin-left: auto;
  }

  .content {
    display: flex;
    align-items: center; // Center vertically
    height: 100vh; // Full height of the viewport
    width: 100%;
    flex-direction: column;
    padding-top: $scale5;
  }

  .logo {
    width: 200px;
    padding-bottom: $scale5;
  }

  .instructions {
    background-color: $c-beige-200;
    width: 650px;
    padding: $scale6 $scale8;
    text-align: center;
    border-radius: $radius-lg;
    position: relative;
    transition: border-radius 0.3s ease-in-out, transform 0.3s ease-in-out; // Add transition for both border-radius and transform

    @include respond-to("portrait") {
      width: 90%;
      padding: $scale $scale;
    }

    &:hover {
      button {
        transform: scale(1.05); // Add scale transform on hover
      }
    }

    button {
      padding: $scale2 $scale4;
      font-size: 1.2em;
    }
  }

  .contactCard {
    width: 80%;
    margin: auto;
    margin-top: $scale5;
  }
}
