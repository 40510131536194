@import "components/global.scss";

.base {
  position: relative;
  padding: $scale;
  padding-bottom: $scale3;
  z-index: 1;
  background-color: $c-beige-200;
  border-radius: $radius2;
  text-align: left;
  margin-bottom: $scale3;

  h2 {
    color: $c-brown-400;
    font-size: $scale1;
    margin-bottom: $scale-2;
  }

  p {
    line-height: $scale;
    margin-bottom: $scale3;
    line-height: $scale2;
    color: $c-text-med-dark;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.account {
    text-align: left;
  }

  header {
    margin-top: 0;
    padding-top: 0;
  }

  button {
    background-color: transparent;
    color: $c-brown-400;
    font-size: $scale;
    margin: 0;
    margin-bottom: $scale-2;

    svg:first-child {
      float: left;
      margin-right: $scale-3;
      top: $scale-6;
      color: $c-brown-400;
      transition: all 200ms;
    }
  }
  button:hover {
    background-color: #78b6cd;
    color: white;

    svg {
      color: white;
    }
  }
}

.card .description {
  margin-bottom: $scale4;
}

.card .header {
  position: relative;
  overflow: hidden;
  text-align: left;
  color: $c-text;
  background-color: white;
  border-bottom: 1px dotted $c-border;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

.noPadding {
  padding: 0;
}

.highlight {
  background-color: $c-blue-400;
}

.last {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
