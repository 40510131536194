@import "components/global.scss";

.wrapper {
  padding: $scale;
}

.nav {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: auto;

  @include respond-to("portrait") {
    max-width: 100%;
  }

  a {
    color: white;
    font-size: $scale-1;
    margin-right: $scale;
    text-decoration: none;
    margin: $scale;
    width: 90px;

    &:last-child {
    }
  }
}

button {
  margin-left: auto;
}

.transparent {
  background-image: none;
  background-color: transparent;
}
