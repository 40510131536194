@import "components/global.scss";

.base {
  position: relative;
  padding-bottom: 1.3em;

  input {
    padding-left: $scale4;
    border-radius: $radius-lg;
    width: 100%;
    background-color: transparent;
    &:valid {
      background-color: transparent;
    }
    &:focus {
      background-color: $c-beige-200;
    }
  }
}

.button {
  background-color: transparent;
  position: absolute;
  left: 2px;
  top: -4px;
  cursor: pointer;
  opacity: 0.5;
  transform: translateY(-5%);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: transparent;
    opacity: 1;
    svg {
      color: $c-dark-300;
    }
  }

  svg {
    color: $c-dark-100 !important;
  }
}
