@import "components/global.scss";

.auth {
  text-align: center;
  @include respond-to("portrait") {
    margin-bottom: $scale5;
  }

  header + section > button {
    display: block;
    margin: 0 auto $scale1;
  }
}
