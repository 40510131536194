@import "components/global.scss";

.base {
  display: inline-block;
  margin: 0 0.25em;
  padding: 0em 0.65em;
  color: white;
  background-color: $c-text-dark;
  font-size: 0.8em;
  text-align: center;
  text-transform: uppercase;
  border-radius: $radius;
}

.blue {
  background-color: $c-blue-300;
}

.red {
  background-color: $c-red;
}

.green {
  background-color: $c-green;
}

.orange {
  background-color: $c-orange;
}
