@import "components/global";

html {
  background-color: $c-beige-50;

  /* width */
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $c-brown-100;
    border-radius: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  ::-moz-scrollbar-thumb {
    background: $c-brown-100;
    border-radius: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  ::-ms-scrollbar-thumb {
    background: $c-brown-100;
    border-radius: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  /* Fade out the handle when not being hovered or scrolled */
  ::-webkit-scrollbar-thumb:not(:hover),
  body:not(:hover) ::-webkit-scrollbar-thumb {
    opacity: 0;
  }

  ::-moz-scrollbar-thumb:not(:hover),
  body:not(:hover) ::-moz-scrollbar-thumb {
    opacity: 0;
  }

  ::-ms-scrollbar-thumb:not(:hover),
  body:not(:hover) ::-ms-scrollbar-thumb {
    opacity: 0;
  }
}

#root {
  height: 100%;
}

body {
  font-size: $body-font-size;
  min-height: 100vh;
  padding: 0;
  width: 100%;
  background-attachment: fixed;
  //overflow-x: hidden;
  background: linear-gradient(
    to bottom right,
    hsla(47, 100%, 96%, 1),
    hsla(47, 100%, 100%, 1)
  );
  background-repeat: no-repeat;
  background-attachment: fixed;

  &.disableOverflow {
    overflow-y: hidden;
  }

  table {
    width: 100%;
  }

  main.ai {
    @include respond-to("landscape") {
      padding-left: $scale11 + $scale7;
      padding-right: $scale7 + $scale8 + $scale6;
    }
  }

  .wide-margin {
    @include respond-to("landscape") {
      padding: 0 $scale6;
      padding-left: $scale11 + $scale7;
    }

    @include respond-to("portrait") {
      padding: 50px $scale3 0 $scale3;
    }
    padding-bottom: 10px;

    width: 100%;
    height: 100%;
    text-align: left;
  }

  #text main {
    padding-bottom: 100px;
  }

  /* TWO COLUMN FLEXBOX */
  .chat-container {
    //display: flex;
    overflow-y: scroll;
  }

  .chat-text {
    flex: 1;
    // display: flex;
    // flex-direction: column;
    position: relative;
    min-height: 100vh; /* Set minimum height of container to full viewport height */
    height: 100%;
    width: 100%;
  }

  // not using this anymore
  .btn-toggle-history {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 110;
    background-color: transparent;
    color: $c-brown-500;

    @include respond-to("landscape") {
      display: none;
    }

    &:hover {
      color: $c-brown-600;
      background-color: transparent;
    }
  }

  .history-container {
    position: fixed;
    right: 0;
    top: 0;
    width: $scale8 + $scale7 + $scale3;
    padding-left: $scale4;
    margin-right: $scale4;
    @include respond-to("portrait") {
      display: none;
    }

    .inner-div {
      margin-top: $scale4;
    }
  }

  .chat-input-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include respond-to("landscape") {
      margin: 0 $scale7 + $scale8 + $scale2 0 $scale11 + $scale3;
    }

    @include respond-to("portrait") {
      padding: 0 $scale;
    }
  }

  .chatHeader {
    h1 {
      display: inline;
      float: left;
      margin-bottom: 0;
    }
    button {
      float: right;
    }
  }

  header {
    padding-top: $scale2;
  }

  header p {
    padding-bottom: $scale2;
  }

  header.help {
    margin: auto;
    width: 80%;
    margin-bottom: $scale4;
  }

  hr {
    margin-bottom: $scale1;
  }

  button {
    background-color: $c-button-blue;
    color: $c-text-light;
    border-radius: 50px;
    border: none;
    padding: 10px 25px;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      background-color: $c-button-hover-blue;
      transition: all 0.25s;
    }
  }

  .topics button {
    margin: $scale-4;
  }

  button.black-btn {
    background-color: $c-button-black;
    &:hover {
      background-color: $c-button-hover-black;
      &:active {
        background-color: $c-button-active-black;
      }
    }
  }
  button.red-btn {
    background-color: $c-button-red;
    color: $c-dark-500;
    &:hover {
      color: $c-text-light;
      background-color: $c-button-hover-red;
      &:active {
        background-color: $c-button-active-red;
      }
    }
  }

  button.green-btn {
    background-color: $c-button-green;
    &:hover {
      background-color: $c-button-hover-green;
      &:active {
        background-color: $c-button-active-green;
      }
    }
  }

  .promptCard button {
    margin: $scale-4;
    padding-top: 4px;
  }
  .promptCard button.active {
    background-color: $c-button-black;
  }

  .promptHeader button:hover {
    transform: scale(1.1); /* Adjust 1.1 to whatever scale factor you like */
  }

  h1 span {
    color: $c-blue-300;
  }

  .notActive .promptCard,
  .notAllowed .promptCard {
    opacity: 0.5;
    box-shadow: none;
  }

  div.lock {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    font-size: $scale-1;
    background-color: #d59e95;
    border-radius: $radius;
    padding: 0;
    display: block;
  }

  .hidden {
    display: none;
  }

  .promptList h2 {
    margin-bottom: $scale;
  }

  .promptCard header {
    padding-top: $scale-5;
  }

  .promptCard button {
    background-color: transparent;

    &:hover,
    &:active {
      transform: scale(1.1);
      background-color: rgba(1, 12, 97, 0.3);
      svg {
        padding: 2px;
      }
    }

    &:active {
      svg {
        padding: 0;
      }
    }
  }

  .promptCard p.promptMsg,
  .promptCard cite {
    margin-top: $scale1;
    margin-bottom: $scale1;
    font-size: $scale-1;
    display: block;
    color: $c-text-dark;
    //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    //border-radius: $radius2;
    //padding: $scale;
    //background-color: $c-bg-200;
  }

  .promptCard {
    cursor: pointer;
    border-radius: $radius2;
    transition: transform 0.3s;

    .thumbnail {
      transform: scale(1.05);
    }
    &:hover,
    &:active {
      h3,
      .promptMsg {
        color: floralwhite;
      }

      .thumbnail {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Adding shadow */
        transform: scale(1.07);
        transition: transform 0.5s;
      }

      .buttonBar {
        background-color: rgba(1, 12, 97, 0.3);
        transition: transform 1s;
        transform: scale(1.05);
      }
      border-color: #fffdf5;
      background: linear-gradient(-45deg, #ee7752, #9666b2, #5fa1ba, #fffdf5);
      box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.3),
        0 10px 23px 0 rgba(0, 0, 0, 0.1);

      background-size: 400% 400%;
      animation: gradient 5s ease infinite;

      transform: scale(1.02);

      .thought-bubble {
        transition: transform 0.2s 1s; // 0.2s duration, 1s delay
        transform: scale(1);
      }
    }

    &:active {
      transition: transform 0.2s;
      transform: scale(1.04);
      z-index: 1000;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .promptCard .thumbnail {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: $radius1;
    margin: auto;
    background-color: floralwhite;
  }

  .promptCard .thumbnail::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(-30deg, rgb(241, 234, 221), rgb(255 253 245));
    mix-blend-mode: darken;
    z-index: 1;
  }

  .promptCard .thumbnail img {
    position: relative;
    width: 100%;
    height: auto;
  }

  .promptCard .buttonBar {
    width: 100%;
    background: $c-brown-200; // change to your requirement
    border-radius: 50px;
    // border: 2px solid $c-brown-50;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .promptCard .progressBar {
    width: 80%;
    background: $c-brown-200; // change to your requirement
    border-radius: 10px;
    height: 2px;
    margin: 10px auto;
  }

  .promptCard .notificationCharm {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 25px;
    height: 25px;
    background-color: $c-red-400;
    box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.6);

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c-text-light;
    font-size: 8px;
  }

  .history h4 {
    font-weight: 100;
    color: $c-brown-200;
    margin-left: $scale;
    svg {
      margin-right: $scale-4;
      margin-bottom: -$scale-5;
    }
  }

  .history .new {
    border-style: dashed;
    border-color: $c-brown-200;
    svg {
      margin-bottom: -$scale-5;
    }
    margin-bottom: $scale-1;
  }
  .history-container button,
  .viewAllHistoryBtn {
    color: $c-brown-100;
    padding: $scale-5;
    padding-left: $scale-2;
    margin: $scale-6;
    margin-bottom: $scale-5;
    font-weight: 100;
    background-color: transparent;
    border-radius: $radius;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: transparent solid 1px;
    width: 100%;

    &:hover {
      background-color: $c-brown-100;
      border: $c-brown-100 solid 1px;
    }

    &:focus {
      background-color: $c-button-active-blue;
      color: $c-text-light;
    }
  }

  .history:hover button {
    color: $c-brown-200;
    &:hover {
      color: $c-brown-400;
    }
  }

  .history:hover button:focus {
    color: $c-text-light;
  }

  .history button.active {
    background-color: $c-button-active-blue;
    color: $c-text-light;
  }

  .history button.viewAll {
    font-weight: 900;
    font-size: $scale2;
    padding-top: 0;
    padding-bottom: $scale-3;
    line-height: $scale-3;
    padding-left: 10px;
    border: none;
    border-radius: 0.3em;
  }
  .history button.viewAll:hover {
    background-color: $c-button-active-blue;
    color: $c-text-light;
  }

  // mobile
  .viewAllHistoryBtn {
    margin-top: $scale-5;
    background-color: transparent;
    width: auto;
    color: $c-brown-200;
    font-size: $body-font-size;

    @include respond-to("landscape") {
      display: none;
    }
    @include respond-to-device("mobile") {
      content: "sd";
    }
    &:hover {
      color: $c-brown-400;
    }
    svg {
      margin-bottom: -$scale-5;
      margin-right: $scale-4;
    }
  }

  #msg {
    padding-bottom: $scale8 + $scale4;
    text-align: left;
    clear: both;
    padding-top: $scale3;
  }

  #msg .loaderSmall {
    visibility: hidden;
    position: relative;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  #msg .loaderSmall svg {
    fill: $c-brown-200;
  }

  .stats {
    position: absolute;
    bottom: $scale;
    left: $scale;
    font-size: $scale-1;
    color: $c-text-light;

    span {
      margin-right: $scale-2;
    }
  }

  .stats .viewEye {
    margin-bottom: -3px;
    margin-right: $scale-4;
  }

  .topicBtn span {
    font-size: $scale1;
    padding-right: $scale-3;
    margin-left: -$scale-4;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  // User history
  .table-wrapper {
    width: 95%;
  }
}

// Chat

#promptDesigner .thumbnailContainer {
  border: $c-brown-200 solid 1px;
  min-height: 200px;
  width: 100%;
  background-color: $c-beige-200;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

#promptDesigner .thumbnailContainerHidden {
  display: none;
}

#promptDesigner label {
  margin-bottom: 3px;

  &:first-child {
    padding-top: 0;
  }
}

#promptDesigner .checkboxes {
  display: flex;
  flex-wrap: wrap;

  legend {
    margin-right: $scale;
  }
}

#promptDesigner input:focus,
#promptDesigner textarea:focus {
  border-color: #1888b1;
  border-width: 3px;
  margin: 0px;
}

#promptDesigner textarea {
  //resize: none;
  width: 100%;
}

#promptDesigner button {
  margin-bottom: $scale-2;
}

header button.delete-btn {
  float: right;
}

#promptDesigner button.removeMessage {
  float: right;
}

#questionForm {
  padding: $scale;

  @include respond-to-device("mobile") {
  }
}

.questionWrapper {
  display: flex;
  position: relative;
  border-radius: 100px;
  border: 1px solid $c-brown-200;
  background-color: $c-beige-50;
  color: $c-text-med-dark;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #fff;
  }

  &:focus {
    border: 1px solid $c-brown-300;
    background-color: #fff;
  }

  outline: none !important;
  border: 2px solid $c-brown-200;
  color: #707070;
}

#question {
  resize: none;
  overflow: hidden;
  width: 90%;
  transition: all 0.5s ease;
  border: none;
  background-color: transparent;

  @include respond-to-device("mobile") {
    width: 75%;
  }
}

#questionForm button {
  background-color: $c-brown-200;
  border-radius: 100px;
  border: none;
  outline: none !important;
  cursor: pointer;
  padding: 10px 10px 6px 10px;
  margin: 10px !important;
  z-index: 100;
  transition: all 0.5s ease;
  &:hover {
    background-color: $c-brown-300;
    color: #fff;
  }
}

#questionForm button:focus:active {
  @include respond-to-device("mobile") {
    background-color: $c-brown-100;
  }
}

#questionForm button:focus {
  background-color: $c-brown-400;

  @include respond-to-device("mobile") {
    background-color: transparent;
    transition: all 0.5s ease;
  }
}

.scrollTo {
  margin-top: 20px;
}

// Account Card

.accountCard button {
  background-color: $c-button-black;
  opacity: 0.5;
  border-radius: $radius-lg;
  margin: 0;
  color: $c-dark-100;
  &:hover {
    background-color: $c-button-hover-black;
    opacity: 1;
  }
  &:active {
    background-color: $c-button-active-black;
  }
}

.align-left {
  text-align: left !important;
}

.account table thead {
  font-weight: 900;
  padding: 200px 0;
}

section.help {
  position: relative;
  top: $scale8;
}

// 404 page
.forofor {
  text-align: center;
  position: relative;
}
.forofor div:first-child {
  padding-top: $scale5;
}
.forofor p {
  color: #fff;
}

form.twoFactorForm {
  display: inline-block;
  margin: auto;
  padding-top: 0;
  margin-top: -30px;
}
form.twoFactorForm div {
  position: relative;
  overflow: visible;
}
form.twoFactorForm input {
  display: inline-block;
  position: relative;
}

.promptCard .thought-bubble {
  position: absolute;
  display: flex;
  right: 50px;
  top: 80px;
  width: 100px;
  align-items: baseline;
  justify-content: flex-end;
  transform: scale(0);

  .circle {
    border-radius: 50%;
    background: floralwhite;
    margin-right: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .small {
    width: 6px;
    height: 6px;
    animation: example 3s ease-in infinite;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .medium {
    width: 9px;
    height: 9px;
    animation: example 3s ease-in infinite;
    animation-delay: 0.4s;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .large {
    width: 14px;
    height: 14px;
    animation: example 3s ease-in infinite;
    animation-delay: 0.8s;
  }

  &:hover {
    animation: scale-up 0.3s;
  }
}

@keyframes example {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
  }
  10% {
    transform: scale(1);
    animation-timing-function: ease-in;
  }

  50% {
    transform: scale(1.3);
    animation-timing-function: ease-out;
  }

  60% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
