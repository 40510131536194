/* modular scale em */
$scale-6: 0.1em;
$scale-5: 0.2em;
$scale-4: 0.4096em;
$scale-3: 0.512em;
$scale-2: 0.64em;
$scale-1: 0.8em;
$scale: 1em;
$scale1: 1.25em;
$scale2: 1.5625em;
$scale3: 1.953125em;
$scale4: 2.44140625em;
$scale5: 3.0517578125em;
$scale6: 3.8146972656em;
$scale7: 4.768371582em;
$scale8: 5.9604644775em;
$scale9: 7.4505805969em;
$scale10: 9.3132257462em;
$scale11: 11.6415321827em;
$scale12: 14.5519152284em;
$scale13: 18.1898940355em;
$scale14: 22.7373675443em;
$scale15: 28.4217094304em;
$scale16: 35.527136788em;
$scale17: 44.408920985em;
$scale18: 55.5111512313em;
$scale19: 69.3889390391em;
$scale20: 86.7361737988em;

$radius-sm: 0.25em;
$radius: 0.5em;
$radius1: 0.75em;
$radius2: 1em;
$radius3: 1.5em;
$radius-lg: 3em;
$radius-xlg: 4em;
$mobile: 600px;
$max-width: 650px;

$line-height: $scale2;
$body-font-size: 1.6rem;

// FAL Here is an updated way to handle media queries for screen sizes
// Define your breakpoints
// Define your breakpoints
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-large-desktop: 1280px;

@mixin respond-to($orientation) {
  @if $orientation == "portrait" {
    @media (max-aspect-ratio: 13/11) {
      @content;
    }
  } @else if $orientation == "landscape" {
    @media (min-aspect-ratio: 13/11) {
      @content;
    }
  } @else {
    @warn 'Invalid orientation value: #{$orientation}.';
  }
}

// Mixin for handling device-specific media queries
@mixin respond-to-device($device) {
  @if $device == "mobile" {
    @media (max-width: $breakpoint-mobile) {
      @content;
    }
  } @else if $device == "tablet" {
    @media (max-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $device == "desktop" {
    @media (max-width: $breakpoint-desktop) {
      @content;
    }
  } @else if $device == "large-desktop" {
    @media (max-width: $breakpoint-large-desktop) {
      @content;
    }
  } @else {
    @warn 'Invalid device value: #{$device}.';
  }
}

.example {
  font-size: 16px;

  @include respond-to("portrait") {
    font-size: 14px;
  }

  @include respond-to("landscape") {
    font-size: 18px;
  }

  @include respond-to-device("mobile") {
    font-size: 12px;
  }
}
