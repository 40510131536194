@import "components/global.scss";

.base {
  padding-bottom: $scale2;

  .chatMessageText {
    white-space: pre-wrap;
    unicode-bidi: embed;
    font-size: $scale;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: $line-height;
    word-break: break-word;
  }

  .icon {
    margin-right: 0.8rem;
    float: left;
    width: 19px;
    height: 19px;
    position: relative;
    top: 0.1em;
  }
  .circle {
    display: block;
    float: left;
    width: $scale1;
    height: $scale1;
    border-radius: 50%;
    position: relative;
    margin-right: 0.5rem;
    top: 0.1em;
  }

  .circle:last-of-type {
    animation: blink 1s linear infinite;
  }

  .assistant {
    background-color: $c-blue-50;
  }

  .user {
    background: fixed;
  }
}
