@import "components/global.scss";

.loading {
  opacity: 0.5;
}

.button {
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
}
