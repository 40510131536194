@import "components/global.scss";

.noSchoolCard {
  .container {
    padding: $scale8 $scale8;
  }

  .icon {
    margin: -30px auto 20px auto;
    color: $c-blue-200;

    svg {
      height: 100px;
      color: $c-blue-200;
    }
  }

  section {
    &:first-of-type {
      margin-top: $scale4;

      h2 {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }

  section div {
    vertical-align: middle;
    padding: $scale;
  }
}
