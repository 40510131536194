@import "components/global.scss";

@keyframes blink {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

.sidebar {
  @include respond-to("landscape") {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 200px;
    z-index: 101;
    padding: $scale;
    transition: all 0.2s ease-out;
    text-wrap: nowrap;
    overflow: hidden;

    box-shadow: 0.5em 0em 1em rgba(0, 0, 0, 0.1);

    a span.label,
    div span.label {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }

    .logoLink {
      top: 30px;
      left: 0px;
    }

    .logoLink img {
      width: 100px;
      position: relative;
      left: -10px;
      top: 20px;
      margin: auto;
    }

    &.expanded {
      .logoLink img {
        animation: bounce 2s; // Apply the bounce animation
      }
      background-color: $c-beige-100;
    }

    .nav-links {
      padding: 0;
      margin-top: $scale8;

      a,
      div {
        position: relative;
        display: block;
        text-decoration: none;
        color: $c-text;
        cursor: pointer;
        width: 100% !important;
        height: $scale4;
        padding: 0.75em $scale-3;
        font-size: 1em;
        line-height: 1;
        margin-bottom: $scale3;

        .icon {
          position: absolute;
          top: 50%;
          left: $scale-1;
          transform: translateY(-50%);
        }

        .label {
          pointer-events: none;
          margin-left: $scale4;
        }

        &.active,
        &:hover {
          color: $c-text-light;
          border-radius: $radius-lg;
          background-color: $c-button-blue;
        }
        &.active {
        }

        &.active .icon,
        &:hover .icon {
          color: $c-text-light;
        }
      }
    }
  }
}

.popup {
  .nav-bar {
    display: none;
  }

  @include respond-to("portrait") {
    width: 100%;
    z-index: 115;
    background-color: transparent;

    > a {
      display: none;
    }

    .nav-bar {
      display: flex;
      position: fixed;
      align-content: center;
      top: 0;
      height: 50px;
      background-color: $c-beige-200;
      width: 100%;
      z-index: 10;
      box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.2);
      transition: top 0.25s ease-in-out;

      .logoLink {
        display: block;
        width: 60px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .logoSVG .tyr {
          fill: $c-tyro-brown;
        }

        .logoSVG .bot {
          fill: $c-tyro-blue;
        }
      }
    }

    .btn-togglenav {
      margin-left: $scale;
      z-index: 110;
      background-color: transparent;
      color: $c-brown-500;
      width: 50px;
      height: 100%;
    }

    .btn-toggle-history {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 110;
      background-color: transparent;
      color: $c-brown-500;
    }

    .ai .btn-toggle-history {
      display: block;
    }

    &.open {
      .nav-links {
        display: block !important;
      }
      .nav-bar {
        position: fixed;
      }
    }

    .nav-links {
      position: fixed;
      display: none !important;
      padding: $scale3;
      padding-top: $scale8;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      background-color: $c-beige-200;
      box-shadow: 0 0 5em rgba(0, 0, 0, 0.25);
      transition: all 0.5s ease-in-out;
      width: 100%;
      height: 100%;

      a,
      div {
        position: relative;
        display: block;
        float: none;
        padding: $scale $scale;
        margin: 0 10% $scale2 10%;
        text-decoration: none;
        cursor: pointer;
        border-radius: $radius-lg;
        color: $c-text-light;
        border-radius: $radius-lg;
        background-color: $c-button-blue;
        background: lighten($c-button-blue, 5%);

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: $c-text-light;
        }

        svg {
          display: inline-block;
          color: $c-text-dark;
          margin-left: 0.75em;
        }

        .label {
          margin-left: $scale4;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          color: $c-bg;
          border-radius: $radius-lg;
          background-color: $c-button-blue;
        }

        &.active .icon {
          color: $c-bg;
        }

        &.active svg {
          color: $c-text-light;
        }
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.1);
  }
}
