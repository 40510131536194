@import "components/global.scss";

.base {
  position: relative;
  padding: $scale;
  z-index: 1;
  background-color: $c-beige-200;
  border-radius: $radius2;
  text-align: center;

  h2 {
    margin: $scale 0;
    text-align: center;
    color: $c-text-med-dark;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.account {
    text-align: left;
  }

  header {
    margin-top: 0;
    padding-top: 0;
  }
}

.card .description {
  margin-bottom: $scale4;
}

.card .editBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  display: block;

  &:hover {
    color: $c-text-light;
    cursor: pointer;
  }
}

.card a {
  color: $c-text-dark;
}

.card .header {
  position: relative;
  overflow: hidden;
  text-align: left;
  color: $c-text;
  background-color: white;
  border-bottom: 1px dotted $c-border;
}

.center {
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

.restrictWidthWide {
  @media (min-width: $mobile) {
    max-width: $scale15 * 2;
  }
}

.noPadding {
  padding: 0;
}

.last {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.card {
  background-color: $c-primary;
  &:hover {
    padding: 0;
  }
}

.card a.button {
  background-color: $c-bg-200;
}

.card:hover a.button {
  background-color: #000;
}

.card a.button:hover {
  background-color: #78b6cd;
}

.card .button {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.card .actions {
  height: 20px;
}

.transparent {
  background-color: transparent;
}
